<template>
    <div class="main-container">
      <h5 class="pageTitle">발송 메시지 등록</h5>
  
      <div class="form-container">
        <div class="form-group">
          <label for="senderNumber">발신번호</label>
          <input v-model="senderNumber" type="text" class="input-box" placeholder="발신번호를 입력하세요" />
        </div>
        
        <div class="form-group">
          <label for="receiverNumber">수신번호</label>
          <input v-model="receiverNumber" type="text" class="input-box" placeholder="수신번호를 입력하세요" />
        </div>
  
        <div class="form-group">
          <label for="sendType">발송형태</label>
          <div class="inline-container">
          <input v-model="sendType" type="text" class="input-box" :placeholder="sendType === '일반' ? '예약' : '발송형태를 선택하세요'" readonly />
            <div class="type-toggle">
              <button @click="setSendType('일반')" :class="{ active: sendType === '일반' }">일반</button>
              <button @click="setSendType('예약')" :class="{ active: sendType === '예약' }">예약</button>
            </div>
          </div>
        </div>
  
        <div class="form-group" v-if="sendType === '예약'">
          <label for="sendDate">발송일시</label>
          <input v-model="sendDate" type="datetime-local" class="input-box wide-input" />
        </div>
  
        <div class="form-group">
          <label for="messageContent">메 시 지</label>
          
          <textarea v-model="messageContent" @input="handleInput" rows="3" maxlength="90" class="textarea-box" placeholder="90바이트 이내에 메시지를 입력하세요"></textarea>
          <div class="char-count">현재 글자 수: {{ byteCount }} / 90 바이트</div>
          
          
        </div>
  
        <div class="button-container">
          <button class="button-list" @click="goToList">목록</button>
          <button class="button-register" @click="registerMsg">등록</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import Swal from 'sweetalert2';
  
  export default {
    data() {
      return {
        // senderNumber: '',
        receiverNumber: '',
        sendType: '일반', // 기본값은 '일반'
        sendDate: '',
        messageContent: '',
        byteCount: 0, // 현재 입력된 글자수 (바이트 기준)
      };
    },
    computed:{
      senderNumber() {
        return this.$store.state.senderNumber; // Vuex state에서 발신 번호 가져오기
      },
    },
    created(){
      // 발신 번호 초기 설정 (필요시)
      if (!this.senderNumber) {
        console.log('기본 발신 번호를 설정합니다:', this.senderNumber);
      }
    },
    methods: {
      updateSenderNumber(newNumber) {
        this.$store.commit('setSenderNumber', newNumber); // 발신 번호 변경
      },
      setSendType(type) {
        this.sendType = type;
        if (type === '일반') {
          this.sendDate = ''; // 즉시 발송일 때는 날짜 선택을 비웁니다.
        }
      },
      goToList() {
        this.$router.push('/sms-read');
      },
      calculateByteLength(text) {
        let byteCount = 0;
        for (let i = 0; i < text.length; i++) {
          const char = text[i];
          byteCount += char.match(/[가-힣]/) ? 2 : 1; // 한글은 2바이트, 영문/숫자/특수문자는 1바이트
        }
        return byteCount;
      },
      handleInput(event) {
        const inputValue = event.target.value;
        const calculatedByteCount = this.calculateByteLength(inputValue);

        // 90바이트를 초과하면 입력 금지
        if (calculatedByteCount > 90) {
          Swal.fire('오류', '메시지는 90바이트를 넘을 수 없습니다.', 'error');
          // 초과된 내용 제거
          event.target.value = this.messageContent;
        } else {
          this.messageContent = inputValue;
          this.byteCount = calculatedByteCount;
        }
      },
      async registerMsg() {
        const byteCount = this.calculateByteLength(this.messageContent);
        if (byteCount > 90) {
          Swal.fire('오류', `메시지는 90바이트를 넘을 수 없습니다. 현재 ${byteCount}바이트입니다.`, 'error');
          return;
        }
  
        if (!this.senderNumber || !this.receiverNumber || !this.messageContent) {
          Swal.fire('오류', '모든 필드를 채워주세요.', 'error');
          return;
        }
  
        const tokenData = JSON.parse(sessionStorage.getItem('token'));
        const token = tokenData ? tokenData.access_token : '';
        
        // requestTime은 언제나 현재 시간을 의미
        const  sendTime = this.sendType === '일반' ? new Date().toISOString() : new Date(this.sendDate).toISOString(); // 일반 발송 시 현재 시간, 예약 발송 시 선택한 발송 일시
        const  requestTime = new Date().toISOString(); 
        const config = {

          //prefix: D:Direct(일반,테스트발송) S:Schedule(예약발송) J:달력일정 예약발송
          method: 'post',
          url: `/sms-main/create_message?cmidPrefix=D`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          data: {
            msg_type: 0,                         
            status: 0,                           
            request_time: requestTime,        
            send_time: sendTime,           
            dest_phone: this.receiverNumber,     
            send_phone: this.senderNumber,       
            msg_body: this.messageContent,       
          },
        };
  
        axios(config)
          .then(() => {
            Swal.fire('성공', '메시지가 등록되었습니다.', 'success');
            this.goToList();
          })
          .catch((error) => {
            Swal.fire('오류', '메시지 등록 중 오류가 발생했습니다.', 'error');
            console.error('메시지 등록 오류:', error);
          });
      },
    },
  };
  </script>
  
  <style scoped>
  .main-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
    max-width: 800px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 10px;
    flex-wrap: nowrap; */
    width: 100%;
  }
  
  .input-box {
    flex: 1;
    padding: 8px;
    margin-right: 40px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    max-width: 200px;
  }
  .input-box.wide-input {
    max-width: 300px; /* 발송일시 칸을 조금 더 넓게 */
  }
  .char-count {
    font-size: 0.9em;
    margin-bottom: 5px;
    color: #555;
  }
  .textarea-box {
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    width: 290px;
    height: 90px;     
    resize: none;
  }
  
  .button-container {
    display: flex;
    gap: 150px;
    justify-content: flex-start;
  }
  
  .button-list {
    background-color: #ff9800;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-register {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  .inline-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .type-toggle button {
    background-color: #e0e0e0;
    border: 1px solid #ccc;
    padding: 5px 10px;
    margin-right: 5px;
    cursor: pointer;
  }
  
  .type-toggle button.active {
    background-color: #b2b1fc;
    color: white;
  }
  </style>
  